export const imageSlides = [
    {
        tag: "Practice 1",
        title: "Say “THANKFUL” first thing in the morning as you awake",
        benefits:
            "This morning connection and verbalizing of the word “GRATEFUL” allows the mind and body to start the day with harmony and positive connection. Try and do this every morning to create a new morning ritual and habit that starts your day off with positive thinking.",
        technique:
            "As your eyes open and you step out of bed, place one foot on the floor and say “Thank”. Place the other foot on the floor and say “Full.” It can be said out loud, whispered, or in your mind.",
        image: "wakeup@2x",
    },
    {
        tag: "Practice 2",
        title: "Mantra “I am Enough”",
        benefits:
            "This simple act of self-love allows us to remember our worth, connect to our heart chakra, and begin a rippling effect of gratitude to ourselves. This practice is essential for connecting back to our Divine Inner Self.",
        technique:
            "This Mantra can be done either sitting or lying down. Place your right hand over your heart and your left hand over your right. Inhale deeply. As you Exhale say “I am Enough,” and repeat 4-6 times.",
        image: "mantra@2x",
    },
    {
        tag: "Practice 3",
        title: "Journal three things that you are grateful for",
        benefits:
            "This practice allows us to tap in to the good that is currently in our life. During difficult times, there is always something positive we can reflect on and be thankful for. Placing the words on paper allows us to connect with our heart to the good we have. These sentiments are powerful to refer to and read when we need to recalibrate and shift our focus.",
        technique:
            "Keep a journal and pen nearby wherever you spend your morning. As your day begins and before you start any tasks, open your journal and write three things you are Grateful for. This can be simple statements or a few sentences of gratitude for what is currently going well in your world.",
        image: "journal@2x",
    },
    {
        tag: "Practice 4",
        title: "Noticing the world around you",
        benefits:
            "Taking time to stop and notice our surroundings with our whole being helps us to connect to all things in our environment. When we take time to stop and feel, connect to ourselves, and notice all the beauty we sometimes take for granted, this daily pause can feel like a mini break and be rejuvenating for the soul.",
        technique:
            "Step outside for a few minutes every day and take a moment to look around: Look down, look up, and from side-to-side and notice with all your senses what you see, hear, smell, and feel in nature. Focus on the colors, sounds, and feel the temperature and climate.",
        image: "aroundyou@2x",
    },
    {
        tag: "Practice 5",
        title: "Gratitude Object",
        benefits:
            "Throughout the day you will be focusing on the good. This practice can have a great impact on our mood, sleep, and relationships. It allows us to face the day from a place of serenity and calm and makes space for the good to come in and be felt. It’s the gentle nudge of gratefulness that can have a rippling effect on our daily experiences.",
        technique:
            "Choose an object like a rock or crystal and carry it with you throughout the day. Place it where you can see it and keep it nearby. When you see it, think of something in the moment that you are grateful for. Keep this simple and organic--the first thought that comes to your mind.",
        image: "object@2x",
    },
    {
        tag: "Practice 6",
        title: "Move with Gratitude",
        benefits:
            "Going inward during physical movement allows us to appreciate the capacity of our bodies in the moment. We become more focused on the experience and do not take for granted the gift of being able to move in that moment. Going inward, seeking connection, and feeling fortunate in our abilities within our bodies is one of the greatest acts of self-love.",
        technique:
            "While you are moving, focus your mind specifically on all the body parts that are moving well. Notice how breathing feels while you are running. Notice the parts of your body connected to the reformer during footwork. Notice how the ground feels beneath your feet while in a yoga pose.",
        image: "movewithgratitude@2x",
    },
];

export const giftOfLove = [
    {
        tag: "Practice 1",
        title: "Acts of Service",
        copy: "This love language centers on doing activities that make life easier or more enjoyable for other people. Examples include running errands or doing chores. Could you give someone the gift of time? Know someone who is swamped with daily tasks? How about you give them the gift of time by performing some of their duties? An example would be a busy parent driving their kids around for multiple after school activities. Give the gift of your time to perform this task while the parent takes the evening off.",
        image: "acts-of-service",
    },
    {
        tag: "Practice 2",
        title: "Quality Time",
        copy: "This love language centers around togetherness. Can you give someone your undivided attention? Could you give someone the gift of your company? How many times have we told a friend or family member that we need to “get together” soon? Make a date, confirm it, and meet.",
        image: "quality-time",
    },
    {
        tag: "Practice 3",
        title: "Gift Giving",
        copy: "This love language centers around receiving tangible and intangible gifts. Could you give someone the gift of gratitude? We are surrounded by so much immediate gratification. Make a list of all the little things that bring a smile to your face. Can you send a hand written note of thanks to those friends and family?",
        image: "gift-giving",
    },
    {
        tag: "Practice 4",
        title: "Words of Affirmation",
        copy: "This love language centers around words spoken or written that confirm, support, uplift another person in a positive manner. Could you give someone the gift of affirmation? Bring positivity into the workplace or next gathering. Pick 2-3 people and intentionally focus on their positive traits and tell them.",
        image: "words-affirmation",
    },
    {
        tag: "Practice 5",
        title: "Physical Contact",
        copy: "This love language centers around the physical touch. Ways to show excitement, concern, care, and love through hugs, pats on the back, holding hands, and other thoughtful touches. Could you give someone the gift of contact (of course, everything within reason and consent)? Do you know someone who tends to offer physical connection when communicating with you? Maybe close the communication loop by offering some type of physical contact you are comfortable with, including a pat on the shoulder, handshake, fist pump, etc.",
        image: "physical-contact",
    },
];

export const selfCare = [
    {
        tag: "Practice 1",
        title: "Schedule It",
        copy: "Appointments have value and seeing an appointment on the schedule is apt to make us jump to action! By creating an appointment for self-care, we’re telling ourselves that this action deserves prioritization, and we’re much more likely to keep that appointment if we see it visually.",
        image: "schedule-it-new@2x",
    },
    {
        tag: "Practice 2",
        title: "Post-It Prompts",
        copy: "Even with the best scheduling strategy, sometimes a full 60-minute workout isn’t impossible. Post-Its to the rescue! Jot down your favorite (and perhaps your nemesis) exercises onto sticky notes and place them around the high traffic areas in your home or office space, for a quick and easy remedy to your scheduling woes. When you walk past a note, pause, and do the exercise listed. It may seem like nothing, but research shows that shorter bouts of exercise can help create long term habits and can be much more fun!",
        image: "post-it@2x",
    },
    {
        tag: "Practice 3",
        title: "Fun With Friends",
        copy: "Having a buddy to encourage, motivate, and commiserate with can help you stay focused on your goals - especially when you’re striving to create new habits. From accountability (no one wants to let their friends down) to comfort (trying new things can be hard), having a friend you can trust to support your goals can be a critical part of your self-care strategy.",
        image: "fun-with-friends@2x",
    },
    {
        tag: "Practice 4",
        title: "Reward Good Behavior",
        copy: "If it works for children and pets, it can work for us too! Rewarding ourselves for achieving our goals is a great way to reinforce habit formation - it allows us to see our growth, serves as a physical manifestation of our change, and helps us develop our intrinsic motivation “muscles” through extrinsic rewards. With time, it will become easier for us to continue to support our new habits (or break bad ones). Be sure to choose a reward that’s relevant to the habit you’re trying to create, so you’re able to readily associate the habit with the joy of success!",
        image: "reward-behavior@2x",
    },
];

export const revampedResolutions = [
    {
        tag: "Practice 1",
        copy: `
            <div>
                <p class='font-26'>Instead of:</p>
                <span>Do a full foam roller release workout.</span>
            </div>
            <div>
                <p class='font-26'>Small-Step Solution:</p>
                <span>“Start the day by choosing one myofascial release exercise.” <br /> ~ Adriana Bires</span>
            </div>
        `,
        image: "revamped-resolution-1@2x",
    },
    {
        tag: "Practice 2",
        copy: `
            <div>
                <p class='font-26'>Instead of:</p>
                <span>Do a 30-Day sugar detox!</span>
            </div>
            <div>
                <p class='font-26'>Small-Step Solution:</p>
                <span>“Start each morning with a protein-filled breakfast with low glycemic fruit such
                as cottage cheese or Greek yogurt and blueberries, strawberries, and blackberries.” <br /> ~ Erika Quest</span>
            </div>
        `,
        image: "revamped-resolution-2@2x",
    },
    {
        tag: "Practice 3",
        copy: `
            <div>
                <p class='font-26'>Instead of:</p>
                <span>Spend too much time on programming MY workouts.</span>
            </div>
            <div>
                <p class='font-26'>Small-Step Solution:</p>
                <span>“Invest in other trainers to program for my personal weekly workouts. This
                way, it’s one less thing I need to think about, and I get the consistency and accountability message, knowing someone I trust has taken the time to prepare these for me!” <br /> ~ Bonne Benesh</span>
            </div>
        `,
        image: "revamped-resolution-3@2x",
    },
    {
        tag: "Practice 4",
        copy: `
            <div>
                <p class='font-26'>Instead of:</p>
                <span>No screen time in the bedroom.</span>
            </div>
            <div>
                <p class='font-26'>Small-Step Solution:</p>
                <span>“I will set an alarm that 10 minutes after going to bed, my phone must be on
                do not disturb and face down. I will allow for one episode or show not more than an hour long before lights out.” <br /> ~ Portia Page</span>
            </div>
        `,
        image: "revamped-resolution-4@2x",
    },
    {
        tag: "Practice 5",
        copy: `
            <div>
                <p class='font-26'>Instead of:</p>
                <span>Drink my weight in water each day.</span>
            </div>
            <div>
                <p class='font-26'>Small-Step Solution:</p>
                <span>“Start the day with a 16oz. glass of water and keep it filled and out on the
                counter or desk I work closest to.” <br /> ~ Erika Quest</span>
            </div>
        `,
        image: "revamped-resolution-5@2x",
    },
    {
        tag: "Practice 6",
        copy: `
            <div>
                <p class='font-26'>Instead of:</p>
                <span>Start each day by sitting at my desk.</span>
            </div>
            <div>
                <p class='font-26'>Small-Step Solution:</p>
                <span>Use a tennis ball in my upper back and scapula to release my neck and
                prepare for a more pain free day!” <br /> ~ Ivy Pan</span>
            </div>
        `,
        image: "revamped-resolution-6@2x",
    },
    {
        tag: "Practice 7",
        copy: `
            <div>
                <p class='font-26'>Instead of:</p>
                <span>Get 10,000 steps in per day.</span>
            </div>
            <div>
                <p class='font-26'>Small-Step Solution:</p>
                <span>“I will look at my current daily step count and increase it by 1,000 steps to
                slowly add more movement to my day.” <br /> ~ Ana Caban</span>
            </div>
        `,
        image: "revamped-resolution-7@2x",
    },
];

export const mindfulness = [
    {
        tag: "Practice 1",
        title: "Breathing the body into the present moment",
        copy: `
            <p>
                <b>Why do this:</b> Brings you into your body, intentionally connects you with your breath, and allows you to get/stay present in your physical surroundings.
            </p>
            <p>
                <b>How to begin:</b> Eyes closed in a relaxed position. Start by hearing and feeling your breath, allowing it to be natural. See your breath as a lightly colored fog and begin to send your breath to your feet. See and feel the breath fill your feet, expanding into all the nooks and crannies. Let the breath bring any and all sensations in your feet to life including the outer edges where you feel the air on your skin. Continue the same practice up your legs and up through the body. Once you’ve done the whole body imagine that you are being breathed without any effort on your part, acknowledge the breath as a constant that helps keep you grounded and present.
            </p>
        `,
        image: "mindfulness-1@2x",
    },
    {
        tag: "Practice 2",
        title: "Anchoring Into Your Center - Finding Your Gold Cord",
        copy: `
            <p>
                <b>Why do this:</b> Your gold cord gives you a visual anchor, that will help you better feel whole and move holistically. This practice gives us a chance to enhance the idea of centering so that our attention is less likely to waiver and our movements more likely to feel complete.
            </p>
            <p>
                <b>How to begin:</b> You can do this anywhere, and in any position. Begin by closing your eyes. Imagine a gold cord running from your center (3 inches or so below your bellybutton) all the way to the center of the earth. See and feel energy pulsing from your center to the earth’s center. You can visualize the energy as a subtle ebb and flow that radiates at a slow and easy rhythm. As you breathe in feel energy being restored to you. As you exhale feel any uneasiness release downward. Stay with this for 5-10 breaths.
            </p>
        `,
        image: "mindfulness-2@2x",
    },
    {
        tag: "Practice 3",
        title: "Using subtle body awareness in your practice",
        copy: `
            <p>
                <b>Why do this:</b> For the reasons described above as well as to build not only body awareness but body intelligence and proprioception. (The subtle body is often linked to chakras, meridians and the fascial system, which is highly innervated and largely responsible for proprioception.
            </p>
            <p>
                <b>How to begin:</b> Do exactly what was described earlier and locate the awareness of your body's very “aliveness”. Giving it a color or seeing it as a field of light can be very useful. Scan your body looking for areas where your awareness is brighter or more intense, as well as where it might be dim or non-existent. With each breath allow the brighter areas to expand and spread.
            </p>
        `,
        image: "mindfulness-3@2x",
    },
    {
        tag: "Practice 4",
        title: "Using three-dimensional breath awareness in your practice",
        copy: `
            <p>
                <b>Why do this:</b> The 3-dimensional breath is a clear, easy way to find balance in the front, sides and back of the body. Improving movement in these spots allows us to better facilitate healthy alignment and function.
            </p>
            <p>
                <b>How to begin:</b> Sit or stand comfortably with your eyes closed. Take a moment to locate your breath in the body, notice the quality of the breath and begin to soften your outer edges so that you can sense the body internally and externally. Repeat each step 4-5 times. Begin by breathing into the front of the body, expanding and exploring all the nooks and crannies. Try to feel the breath from your pubic bone to your throat Second, breath into the sides of the body, opening the body into space as far as you can
                right to left. Feel the breath from the top of your hipbones all the way up the sides of your throat. Third, breath into the back body. Expand the breath across the low back all the way to the back of the head. Each time you exhale let the breath out easily, no effort. Finally, put it all together and breath simultaneously into the front, sides and back of the body feeling as if you were increasing your circumference equally in all directions.
            </p>
        `,
        image: "mindfulness-4@2x",
    },
    {
        tag: "Practice 5",
        title: "The Wave Breath",
        copy: `
            <p>
                <b>Why do this:</b> The wave breath is a beautiful way to experience the ebb and flow of the breath and feel what the spine naturally does in response. It’s an opportunity to experience the complete breath from all directions.
            </p>
            <p>
                <b>How to begin:</b> Begin by exploring your breath in the front, sides and back of the body, feeling as if you were increasing your circumference equally in all directions (three-dimensional breath). Repeat with every breath. Repeat this 4-5 times. Feel your breath move into the bottom third of your torso as if you were breathing in from your tailbone and pubic bone and filling up your lower abdomen in all directions. Notice how your sits bones widen and your lower back begins to slightly arch. Repeat 4-5 times. Bring your breath up from the bottom once again but expand it upward into the middle third of your trunk. Feel the breath expanding into the belly, sides of the waste and into the middle back. Continue to allow extension into the thoracic spine. Repeat this 4-5 times. Each time you exhale let the breath out easily. Bring your breath into the upper third of the trunk feeling the heart rise, the chest and throat open, and
                the chin lift slightly. The spine is gently but fully extended. Allow the breath to empty from the top down, feeling the spine subtly descend into flexion: eyes gaze down, chin drops, chest softens, ribs drop back, low back lengthens and sitz bones slightly narrow. Repeat as many times as feels good.
            </p>
        `,
        image: "mindfulness-5@2x",
    },
];
