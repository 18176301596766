import * as React from 'react';

function Star(props) {
	return (
		<svg
			width={'23'}
			height={'23'}
			viewBox={'0 0 23 23'}
			fill={`none`}
			xmlns={'http://www.w3.org/2000/svg'}
			{...props}
		>
			<path d={'M11.4973 0L14.0786 7.9463H22.4319L15.674 12.8574L18.2553 20.8037L11.4973 15.8926L4.73936 20.8037L7.32067 12.8574L0.562718 7.9463H8.91601L11.4973 0Z'} fill={`currentColor`} />
		</svg>
	);
}

export default Star;
