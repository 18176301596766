import * as React from 'react';

function Play(props) {
	return (
		<svg
			width={56}
			height={56}
			viewBox={`0 0 56 56`}
			fill={`none`}
			xmlns={`http://www.w3.org/2000/svg`}
			{...props}
		>
			<circle
				cx={28}
				cy={28}
				r={28}
				fill={`#fff`}
			/>
			<path
				d={`M26 32.9448v-10l8 5-8 5Z`}
				fill={`#1F1F1F`}
				stroke={`#1F1F1F`}
				strokeWidth={2}
				strokeLinecap={`round`}
				strokeLinejoin={`round`}
			/>
		</svg>
	);
}

export default Play;
