import React, { useState } from "react";

import classNames from "classnames";
import "./css/VideoFull.css";

import Modal from "./Modal";
import Instructors from "./Instructors";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { findImage } from "../assets/utils/functions.js";
import renderIcon from "../assets/utils/renderIcon";

function VideoFull({ title, instructors, video, thumb, bgColor }) {
    const data = useStaticQuery(graphql`
        {
            allFile(filter: { relativeDirectory: { eq: "video" } }) {
                nodes {
                    name
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 80, layout: CONSTRAINED)
                    }
                }
            }
        }
    `);

    const [modal, setModal] = useState(false);

    const ns = "video-full";

    const rootClassnames = classNames({
        [ns]: true,
        [`${ns}--${bgColor}`]: bgColor,
    });

    return (
        <section className={rootClassnames}>
            <main className={`${ns}__container`}>
                <div className={`${ns}__content`}>
                    <div className={`${ns}__heading`}>
                        <h2 className={`${ns}__title`}>{title}</h2>
                        {!!instructors.length && (
                            <div className={`${ns}__instructors`}>
                                <Instructors instructors={instructors} />
                            </div>
                        )}
                    </div>
                    <div className={`${ns}__group`}>
                        <GatsbyImage
                            className={`${ns}__group-image`}
                            image={getImage(findImage(data, thumb))}
                            alt={thumb.split(".")[0]}
                            loading="eager"
                        />
                        <button
                            className={`${ns}__group-icon`}
                            onClick={() => setModal(true)}
                        >
                            {renderIcon("play")}
                        </button>
                    </div>
                </div>
                <Modal
                    isOpen={modal}
                    onRequestClose={() => setModal(false)}
                    contentLabel="video modal"
                >
                    {video && (
                        <div className={`${ns}__media-video`}>
                            <iframe
                                data-media
                                src={video}
                                title={title}
                                frameBorder={0}
                                allowFullScreen
                                allow={`autoplay`}
                            />
                        </div>
                    )}
                </Modal>
            </main>
        </section>
    );
}

export default VideoFull;
