import * as React from 'react';

function Stars(props) {
	return (
		<svg
			width={'124'}
			height={'23'}
			viewBox={'0 0 124 23'}
			fill={`none`}
			xmlns={'http://www.w3.org/2000/svg'}
			{...props}
		>
			<path d={'M11.4973 0L14.0786 7.9463H22.4319L15.674 12.8574L18.2553 20.8037L11.4973 15.8926L4.73936 20.8037L7.32067 12.8574L0.562718 7.9463H8.91601L11.4973 0Z'} fill={`currentColor`} />
			<path d={'M62.6487 0L65.23 7.9463H73.5833L66.8253 12.8574L69.4066 20.8037L62.6487 15.8926L55.8907 20.8037L58.472 12.8574L51.7141 7.9463H60.0674L62.6487 0Z'} fill={`currentColor`} />
			<path d={'M36.7493 0L39.3306 7.9463H47.6839L40.9259 12.8574L43.5072 20.8037L36.7493 15.8926L29.9913 20.8037L32.5726 12.8574L25.8147 7.9463H34.168L36.7493 0Z'} fill={`currentColor`} />
			<path d={'M87.8997 0L90.481 7.9463H98.8343L92.0763 12.8574L94.6576 20.8037L87.8997 15.8926L81.1417 20.8037L83.723 12.8574L76.9651 7.9463H85.3184L87.8997 0Z'} fill={`currentColor`} />
			<path d={'M112.502 0L115.084 7.9463H123.437L116.679 12.8574L119.26 20.8037L112.502 15.8926L105.744 20.8037L108.326 12.8574L101.568 7.9463H109.921L112.502 0Z'} fill={`currentColor`} />
		</svg>
	);
}

export default Stars;
