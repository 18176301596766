export const videoSlides = [
    {
        tag: "EXERCISE 1",
        title: "Supine Rotation on Spinefitter",
        subtitle:
            "<b>Body+Mind Connection:</b> Spinal and trunk mobility, whole body integration, parasympathetic activation",
        benefits:
            "During times of stress and anxiety, the breath, spine, and trunk tend to be held in a protective pattern of tension. Rotation helps to keep the spine, trunk muscles, lungs, shoulders, and hips mobile, creating a sense of freedom in the body. Twisting the body allows the organs to move more freely",
        technique: [
            "<b>Starting Position</b> — Lie on your back on the Spinefitter, a towel rolled and placed lengthwise, or flat on your mat with the feet wider than shoulder width apart, arms comfortably out to the side.",
            "<b>Action</b> — Focus on taking deep, slow breaths in and out through the nose. Gently take the knees to the right, allowing the pelvis and feet to follow the legs. Simultaneously turn the head gently in the opposite direction. Alternate side to side 8-10x each direction",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/WMHD_2_SupineRotation.mp4",
    },
    {
        tag: "EXERCISE 2",
        title: "Movement/Breath Coordination",
        subtitle: "<b>Body+Mind Connection:</b> Breath, Concentration, Memory",
        benefits:
            "Enhanced mental acuity and focus, stimulates release of endorphins and contributes to a sense of relaxation",
        technique: [
            "<b>Starting Position</b> — Lie on the back in a comfortable position with the knees bent, feet flat on the floor about hip distance apart",
            "<b>Action</b> — Focus on taking deep, even breaths of approximately 4 counts in/4 counts out. Create the cadence that is most comfortable for you. As you inhale, simultaneously lift one arm to a 90° shoulder angle (vertical) and the opposite leg to a 90° angle at the thigh with the knee bent. As you exhale, lower the arm and leg to the floor. Coordinate the breath and movement. Alternate 5-8x each side",
            "<b>Progress this exercise:</b> Add another challenge by turning the head in the direction of the moving arm.",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/WMHD_3_MovementBreath.mp4",
    },
    {
        tag: "EXERCISE 3",
        title: "All 4’s Crawling and Child’s Pose",
        subtitle: "<b>Body+Mind Connection:</b> Trunk and limb coordination, natural movement in the body",
        benefits:
            "Helps to “reset” the nervous system,restores the cross-body connections we need for efficientwalking, creates a sense of fun and play",
        technique: [
            "<b>Starting Position</b> — All 4’s position on the floor, with hands under the shoulders and knees under the hips",
            "<b>Action</b> — Crawl forward 5–10 feet and reverse by crawling backwards. Allow the body to move in whatever fashion is comfortable and notice the timing and coordination of crawling. Could you make this movement easier, freer, or more relaxed? Simply play with the movement. Change directions as if you’re a child exploring your world. 2–4 mins. Relax by pressing the hips back over the feet to rest in child’s pose. Breathe deeply 60-90 secs",
            "<b>Progress this exercise:</b> Do these crawling activities in a bear-crawl position by lifting the knees off the floor—keep the knees as low as possible so the trunk stays parallel to the floor",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/WMHD_4_All4sCrawling.mp4",
    },
    {
        tag: "EXERCISE 4",
        title: "Half Kneeling Rotation",
        subtitle: "<b>Body+Mind Connection:</b> Balance, coordination, spine and rib mobility, breath",
        benefits:
            "Opens the ribs, thorax, and shoulder girdle, increases spinal mobility, facilitates improved breathing to provide more oxygen to the body and brain; improves creative and critical thinking",
        technique: [
            "<b>Starting Position</b> — On floor or mat with one knee on floor and the other forward in a ½ kneel position. The front shin and back thigh are at a 90° angle to the floor. The hands are interlaced behind the head (not the neck) with elbows wide. Torso/chest/head face front",
            "<b>Action</b> — Breathe deeply and slowly. Exhale fully and turn the ribs, shoulder girdle and head as far as you can toward the front leg. Pause and inhale fully. Exhale fully as you return to the start position. Exhale fully as you turn the ribs, shoulder girdle, head, and hips/pelvis as far as possible toward the back leg. Pause and inhale fully. Exhale fully as you return to the start position. Repeat 5x and switch legs.",
            "<b>Progress this exercise:</b> For an added challenge, add a prop like Balanced Body’s SmartBell®. Hold the SmartBell out in front of you and turn side-to-side slowly while breathing deeply. If you don’t have a SmartBell, you can use a household item like a broomstick, dowel, or simply hold a towel between your hands. Repeat 5x and switch legs.",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/WMHD_5_HalfKneelingRotation.mp4",
    },
    {
        tag: "EXERCISE 5",
        title: "Floor to Standing",
        subtitle:
            "<b>Body+Mind Connection:</b> Whole body natural movement, coordination, balance, control, problem-solving and spatial awareness",
        benefits:
            "Integrates the feet, legs, and trunk, improves strength and mobility in the trunk and lower body, increases balance and longevity, and supports a sense of feeling grounded and centered",
        technique: [
            "<b>Starting Position</b> — sit on the floor in whatever position is accessible to you. If you have multiple options, great! If you find sitting on the floor challenging, use a pillow, cushion, or stack of towels for support",
            "<b>Action</b> — If possible, sit with the legs crossed. Without using the hands, stand up. With control, lower yourself to the floor. If you can do this, try sitting with the legs crossed the opposite (unfamiliar)way and stand up/sit down with control. If none of this is possible for you right now, explore ways you could move from the floor to standing, including using your hands on a chair for support. For example, you can come up to ½ kneeling as with the previous rotation exercise, hold on to a chair, and stand. Lower yourself to the floor in the same fashion. Start with sit stand 3x and progress to 5x",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/WMHD_6_FloorToStanding.mp4",
    },
    {
        tag: "EXERCISE 6",
        title: "Standing Balance & Coordination",
        subtitle:
            "<b>Body+Mind Connection:</b> Balance, cognition, mental focus, whole body spatial awareness and coordination",
        benefits:
            "Training balance and coordination improves cognition and memory in adults, creating better recall and spatial awareness",
        technique: [
            "<b>Starting Position</b> — stand on your mat or floor, feet approximately hip distance apart",
            "<b>Action</b> — Shift weight to one leg and lift the other leg as high as you can toward the chest with bent knee. Take the lifted leg out to the side, keeping the thigh as high as you can. Lower slowly to the starting position. Repeat on the other side. 5x each leg. If needed, you can support yourself by holding a chair with one hand",
            "<b>Progress this exercise:</b> Stand on a variety of surfaces—grass, a thick pad, uneven ground, sand. Finally, can you repeat the pattern with the eyes closed?",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/WMHD_7_StandingBalance.mp4",
    },
    {
        tag: "EXERCISE 7",
        title: "Coordination with Music",
        subtitle: "<b>Body+Mind Connection:</b> Memory, whole body coordination, endurance, and performance",
        benefits:
            "Moving to music can reduce anxiety and depression, boost memory, build endurance, reduce fatigue, and improve your response to pain",
        technique: [
            "<b>Starting Position</b> — find a song you love that plays at approximately 115-120 bpm. A simple internet search will give you the beats per minute. Alternatively, search “songs with 115-120 bpm.” Stand hip width apart on the floor. Press “play”",
            "<b>Action</b> — In time with the music, step one foot to the side and bend at the hip and knee of the moving leg. Return the leg to start. Alternate side to side. Progress by taking the movement lower and lower, and wider and wider until you are lunging side to side. Add a torso twist and take the opposite elbow towards the inside of the knee. Continue to the end of the song",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/WMHD_8_CoordinationMusic.mp4",
    },
    {
        tag: "EXERCISE 8",
        title: "Breathing on Spinefitter",
        subtitle: "<b>Body+Mind Connection:</b> Breath, Parasympathetic Nervous System activation",
        benefits:
            "Calms the nervous system, slows heart rate, improves oxygen delivery to the brain and body, reduces muscle tension, and creates a sense of well-being",
        technique: [
            "<b>Starting Position</b> — Lie on the back on a Spinefitter. If heat is beneficial to you, use the heated Linum wrap. Alternatively, lie on a heated rolled-up towel (the dryer works well for this purpose). Knees are bent, feet flat",
            "<b>Action</b> — Breathe slow, deep inhalations and exhalations through the nose, allowing the abdomen to rise and fall with the breath. If the mind wanders, bring your awareness back to the simple act of breathing deeply. Continue for 3 – 5 mins",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/WMHD_1_Breathing.mp4",
    },
];

export const nicoGonzalesSlides = [
    {
        tag: "Practice 1",
        title: "Standing roll down sway side-to-side",
        benefits: "This movement sequence brings awareness to the lower back so take your time and let is release.",
        technique:
            "Stand tall with your feet firmly pressed into the ground. Start to roll your spine down and let your upper body hand low with your knees slightly bent. Start to sway your upper body over to one heel then to the other.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_1_V2.mp4",
    },
    {
        tag: "Practice 2",
        title: "Ear to shoulder with chin circles",
        benefits: "This is an amazing way to release tension that might be held in the upper traps and neck muscles.",
        technique:
            "Standing or seated bring one ear to the same side shoulder and turn your head slightly towards the floor. With your opposite arm slightly behind you start to make little chin circles with your head. Perform 3-4 in each direction.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_2_V2.mp4",
    },
    {
        tag: "Practice 3",
        title: "Standing twists",
        benefits: "What a wonderful way to increase circulation, bring clarity, and improve mobility of the body.",
        technique: "Standing with your feet hip width apart start to twist your entire body side to side.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_3_V2.mp4",
    },
    {
        tag: "Practice 4",
        title: "Supine knee sways",
        benefits: "This is a great way to release tension in your lower back and increase mobility in your hips.",
        technique:
            "Start on the floor completely on your back with your feet on the floor knees bent. Start to rock the knees and hips side to side. Allow your lower back to move as well.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_4_V2.mp4",
    },
    {
        tag: "Practice 5",
        title: "Paint the wall stretch",
        benefits: "This is a great way to stretch the front of the chest and shoulder.",
        technique:
            "Standing facing the wall like you are going to do a push up against the wall. As you bring your body closer to the wall slide one hand out to the side along the wall and bend the other arm’s elbow to support you.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_5_V2.mp4",
    },
    {
        tag: "Practice 6",
        title: "Standing knee circles",
        benefits:
            "Feeling a little tight in your hips? Feeling sluggish by sitting down too long? Try these standing knee circles to bring circulation into your entire body!",
        technique:
            "Start standing and bring one knee up and around in a circle before returning back to the floor. Alternate legs.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_6_V2.mp4",
    },
    {
        tag: "Practice 7",
        title: "Standing heel lifts",
        benefits: "This is a wonderful way to bring oxygen into the body mindfully.",
        technique:
            "Begin in the standing position with your knees and toes pointed forward. Place your hands on the ribs. Start to lift your heels off the floor on an inhale and lower the heels back to the ground on the exhale. Breathe deeply so that your ribs move out on the inhale and in during the exhale.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_7_V2.mp4",
    },
    {
        tag: "Practice 8",
        title: "Side breathing (one lung)",
        benefits:
            "Truly a great movement to lengthen the side of body, lower back, and bring awareness to your breathing.",
        technique:
            "Start seated or standing with one hand on the opposite side of your torso along the ribs. Place one hand behind your head and start to side bend away from the hands that are holding your ribs. Start the movement with a small side bend and inhale, then go deeper with another inhale, and finally to your best side bend on your third inhale before returning to the standing position.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_8_V2.mp4",
    },
    {
        tag: "Practice 9",
        title: "Turn the dial shoulder release",
        benefits:
            "Feeling stressed in your neck and shoulders? Get some circulation going in that area with this movement pattern. Allowing the neck and shoulder muscles move through upward and downward rotation truly brings circulation and mobility into that area.",
        technique:
            "Start seated or standing with your hands slightly in front of your shoulders, elbows pointed down and relaxed. Imagine your shoulder blades as dials on the clock. As you start to twist your hands inward your shoulders will start to upwardly rotate - take an inhale here. Return the fingers to point up as you downwardly rotate the shoulders - exhale here.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_9_V2.mp4",
    },
    {
        tag: "Practice 10",
        title: "One Leg Holiday Dance",
        benefits: "Use this leg twist move to bring mobility and circulation to the hips.",
        technique:
            "Start standing and place more weight into your supporting leg. The other leg will start to twist in and out. Turn the knees and toes inward (internal rotation) then turn out (external rotation).",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_10_V2.mp4",
    },
    {
        tag: "Practice 11",
        title: "Back Bend Stretch",
        benefits: "This movement can help counter the effects of sitting for long periods of time.",
        technique:
            "Sit at a desk all day? Tight muscles in the front of your body? Try this back bend stretch on for size. Start standing with your hands on the side or back of your hips. As you inhale start to press your hips forward and beam your chest up towards the ceiling. Exhale to return to the start position.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_11_V2.mp4",
    },
    {
        tag: "Practice 12",
        title: "Seated knee rocks with torso twist",
        benefits:
            "This is a great sequence when you need a little bit of hip and low back movement. Maybe you have been sitting for a long time or feeling stiff around your hips? Doing these knee rocks will definitely loosen you up and getting you going for the rest of your day.",
        technique:
            "Begin seated and support your upper body with your hands slightly behind you on the floor. Knees are bent and feet are flat the floor. Rock your knees and hips side to side but try to keep your upper body steady throughout the movement sequence. Let your hips go with the movement to release the back.",
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/HMHB_Gonz_2212_12_V2.mp4",
    },
];

export const stressSlides = [
    {
        tag: "Practice 1",
        title: "Hummingbird",
        benefits:
            "Humming can calm the nervous system and regulate the heart rate, chest opening allows for improved breathing",
        technique: [
            "<b>Starting Position</b> — Standing with the upper body against a wall or door jamb; arms in cactus position",
            "<b>Action</b> — Inhale (hold); Exhale (gently bring the arms to parallel in line with chest while humming)",
            "<b>Reps</b> — 5 - 8",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/2301_Cauthen_Hummingbird.mp4",
    },
    {
        tag: "Practice 2",
        title: "Cloche",
        benefits: "Releases tension in the back and neck, brings focus to breath for improved breathing",
        technique: [
            "<b>Starting Position</b> — Standing with upper body against a wall; feet one full step ahead of hips; arms by sides",
            "<b>Action</b> — Inhale: roll down to a comfortable spot while keeping low back supported on the wall. Exhale: sway the torso to one side, allowing arms and head to tag along tension-free. Inhale: sway to other side, repeat",
            "<b>Reps</b> — 4 - 6 sways",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/2301_Cauthen_Cloche.mp4",
    },
    {
        tag: "Practice 3",
        title: "Turtle Humming",
        benefits: "Humming can calm the nervous system and regulate the heart rate. Releases tightness in shoulders and neck, stretches chest and thoracic spine",
        technique: [
            "<b>Starting Position</b> — Prone with arms in cactus and forehead resting on a towel",
            "<b>Action</b> — Inhale: Shrug shoulders tight to ears. Exhale: Widen shoulders away from ears as gaze travels along the mat and up to the wall, keeping forearms and palms heavy",
            "<b>Reps</b> — 3 - 5",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/2301_Cauthen_TurtleHumming.mp4",
    },
    {
        tag: "Practice 4",
        title: "X Stretch",
        benefits: "Reduces tightness, increases circulation, brings focus to breath for improved breathing",
        technique: [
            "<b>Starting Position</b> — Supine with arms and legs extended to the four corners of the room",
            "<b>Action</b> — Inhale; extend the opposite arm and leg away from the midline of the body, allowing the back to arch and the throat to open.Exhale; to release to center, allowing the body to “melt” back to a neutral supine position; Repeat on other side",
            "<b>Reps</b> — 3 - 6",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/2301_Cauthen_XStretch.mp4",
    },
    {
        tag: "Practice 5",
        title: "Rolling Clam",
        benefits: "Grounding, stress release",
        technique: [
            "<b>Starting Position</b> — Supine with knees to chest, holding on to knees",
            "<b>Action</b> — Inhale; while holding on, let one arm and knee open to one side Exhale; return to center Repeat on other side",
            "<b>Progression</b> — Inhale; while holding on, let one arm and open to one side and allow the body to roll along with it; head is last to arrive Exhale; open top side of body to other side, allowing the body to roll along with it; head is last to arrive",
            "<b>Reps</b> — 2 - 4",
        ],
        video: "https://s3.amazonaws.com/s3.pilates.com/micro-site/mental-health/videos/2301_Cauthen_RollingClam.mp4",
    },
];
