import * as React from 'react';

function Hamburger(props) {
	return (
		<svg
			xmlns={`http://www.w3.org/2000/svg`}
			fill={`none`}
			viewBox={`0 0 19 17`}
			height={19}
			width={17}
			{...props}
		>
			<path
				stroke={`currentColor`}
				strokeLinejoin={`round`}
				strokeMiterlimit={10}
				strokeWidth={1.8}
				d={`M.0508 1h18M.0508 8.3828h18M.0508 15.6335h18`}
			/>
		</svg>
	);
}

export default Hamburger;
