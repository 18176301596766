import * as React from 'react';

function Pin(props) {
	return (
		<svg
			xmlns={`http://www.w3.org/2000/svg`}
			fill={`none`}
			width={16}
			height={21}
			viewBox={`0 0 16 21`}
			{...props}
		>
			<path
				fill={`currentColor`}
				d={`M14.9043 4.255c-1.19-2.36-3.51-3.94-6.13-4.21-.58-.06-1.17-.06-1.76 0-2.63.27-4.94 1.86-6.13 4.21-1.51 3.02-1.06 6.66 1.15 9.22l5.27 6.4c.27.33.76.38 1.09.11.04-.03.07-.07.11-.11l5.27-6.4c2.19-2.56 2.65-6.2 1.13-9.22Zm-2.34 8.24-4.67 5.68-4.67-5.68c-1.79-2.09-2.17-5.04-.98-7.52.96-1.9 2.81-3.17 4.92-3.39.25-.03.49-.04.73-.04s.48.01.72.04c2.11.22 3.97 1.5 4.92 3.39 1.2 2.48.82 5.43-.97 7.52Zm-4.67-8.61c-2.14 0-3.88 1.74-3.88 3.88 0 2.14 1.74 3.88 3.88 3.88 2.14 0 3.88-1.74 3.88-3.88-.01-2.15-1.74-3.88-3.88-3.88Zm0 6.15c-1.26 0-2.27-1.02-2.27-2.27s1.01-2.28 2.27-2.28 2.27 1.02 2.27 2.27c0 1.26-1.01 2.28-2.27 2.28Z`}
			/>
		</svg>
	);
}

export default Pin;
