import * as React from 'react';

function Clock(props) {
	return (
		<svg
			width={17}
			height={17}
			viewBox={`0 0 17 17`}
			fill={`none`}
			xmlns={`http://www.w3.org/2000/svg`}
			{...props}
		>
			<path
				d={`M8.295 15.59a7.295 7.295 0 1 0 0-14.59 7.295 7.295 0 0 0 0 14.59Z`}
				stroke={`currentColor`}
				strokeWidth={1.4}
				strokeMiterlimit={10}
			/>
			<path
				d={`M8.297 3.288v5.487l3.238 1.189`}
				stroke={`currentColor`}
				strokeWidth={1.4}
				strokeMiterlimit={10}
			/>
		</svg>
	);
}

export default Clock;
