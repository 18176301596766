import React from "react";

import ReactModal from "react-modal";
import "./css/Modal.css";

function Modal({ children, contentLabel = "", isOpen = false, onRequestClose }) {
    const ns = "modal";
    return (
        <section className={ns}>
            <main>
                <ReactModal
                    contentLabel={contentLabel}
                    className={ns}
                    bodyOpenClassName={`${ns}--open`}
                    overlayClassName={`${ns}__overlay`}
                    isOpen={isOpen}
                    onRequestClose={onRequestClose}
                    closeTimeoutMS={400}
                >
                    <div className={`${ns}__content`}>
                        {/* <button className={`${ns}__close`} onClick={onRequestClose}>
                            {renderIcon("close")}
                            <span className={`visually-hidden`}>Close</span>
                        </button> */}
                        {children}
                    </div>
                </ReactModal>
            </main>
        </section>
    );
}

export default Modal;
