import * as React from 'react';

function Calendar(props) {
	return (
		<svg
			xmlns={`http://www.w3.org/2000/svg`}
			fill={`none`}
			height={`17`}
			width={`16`}
			{...props}
		>
			<path
				d={`M12.69 1.52h-1.37V0h-1.16v1.52h-5.1V0H3.85v1.52H2.53C1.14 1.52 0 2.65 0 4.05v9.64c0 1.4 1.14 2.53 2.53 2.53h10.16c1.4 0 2.53-1.14 2.53-2.53V4.05c0-1.4-1.13-2.53-2.53-2.53Zm1.28 11.86c0 .8-.68 1.46-1.51 1.46H2.74c-.83 0-1.51-.65-1.51-1.46V6.75h12.74v6.63Zm.05-7.81H1.18V4.25c0-.84.68-1.52 1.52-1.52h1.15v1.01h1.22V2.73h5.1v1.01h1.16V2.73h1.18c.84 0 1.52.68 1.52 1.52v1.32h-.01Z`}
				fill={`currentColor`}
			/>
		</svg>
	);
}

export default Calendar;
