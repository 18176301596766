import * as React from 'react';

function Stars() {
	return (
		<svg width={'36'} height={'24'} viewBox={'0 0 36 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
			<rect width={'36'} height={'24'} rx={'2'} fill={`currentColor`} />
		</svg>
	);
}

export default Stars;
