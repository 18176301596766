import React from "react";

import "./css/Tooltip.css";

import uniqueId from "uniqid";

function Tooltip({ links, title, credentials }) {
    const ns = "tooltip";

    return (
        <div className={ns}>
            {credentials && (
                <p className={`${ns}__instructor-credits`}>
                    <b>{credentials}</b>
                </p>
            )}
            {title && <p className={`${ns}__instructor-title`}>{title}</p>}
            {links && (
                <ul>
                    {links.map(({ label, link }) => (
                        <li key={uniqueId("bb")}>
                            <a href={link} target="_blank" rel="noreferrer noopener">
                                {label}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Tooltip;
