import * as React from 'react';

function Close(props) {
	return (
		<svg
			width={15}
			height={15}
			viewBox={`0 0 15 15`}
			fill={`none`}
			xmlns={`http://www.w3.org/2000/svg`}
			{...props}
		>
			<path
				d={`m1 1 12.728 12.728M13.727 1 .999 13.728`}
				stroke={`currentColor`}
				strokeWidth={1.8}
				strokeMiterlimit={10}
				strokeLinejoin={`round`}
			/>
		</svg>
	);
}

export default Close;
