import * as React from 'react';

function Document(props) {
	return (
		<svg
			width={16}
			height={20}
			viewBox={`0 0 16 20`}
			fill={`none`}
			xmlns={`http://www.w3.org/2000/svg`}
			{...props}
		>
			<path
				d={`M9.38 1H1v17.97h13.65V5.73L9.92 1h-.54ZM14.65 5.73 9.92 1`}
				stroke={`currentColor`}
				strokeWidth={1.473}
				strokeMiterlimit={10}
			/>
			<path
				d={`M9.05 1.71V6.8h5.31`}
				stroke={`currentColor`}
				strokeWidth={1.473}
				strokeMiterlimit={10}
			/>
		</svg>
	);
}

export default Document;
