import React from "react";
import "./css/Hero.css";

import { StaticImage } from "gatsby-plugin-image";

function Hero() {
    const ns = "hero";

    return (
        <section className={ns}>
            <div className={`${ns}__container`}>
                <div className={`${ns}__main`}>
                    <div className={`${ns}__main-body`}>
                        <h1 className={`${ns}__main-title`}>Healthy Mind, Healthy Body</h1>
                        {/* <p className={`${ns}__main-subtitle`}>
                            This is a promise, not a slogan. From equipment to learning to
                            listening, we are with you every step of your journey. Welcome
                            to all that moves you.
                        </p> */}
                    </div>
                    <StaticImage
                        className={`${ns}__main-image`}
                        src="../assets/images/hero/hero@3x.jpg"
                        alt="main image"
                        placeholder="blurred"
                        quality={90}
                        loading="eager"
                    />
                </div>
                <div className={`${ns}__desc`}>
                    <p>
                        Let's make 2024 the year where we start small and gain big. Research suggests that
                        micro-stepping your way to positive habits and behaviors is a better way to produce long-term
                        results. In this series of Revamped Resolutions, we share ways to make tiny changes in a variety
                        of aspects of your daily life that are realistic and will support you achieving your individual
                        goals. Choose one or stack these micro-steps together to design your own journey. We know you
                        can do it, you know you can do it…. let's work together to WIN in 2024!
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Hero;
