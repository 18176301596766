import React from 'react';

import Arrow from '../icons/Arrow.js';
import Calendar from '../icons/Calendar';
import Cart from '../icons/Cart';
import CheckIcon from '../icons/CheckIcon';
import Clock from '../icons/Clock';
import Close from '../icons/Close';
import ColorSwatch from '../icons/ColorSwatch';
import Document from '../icons/Document';
import Filter from '../icons/Filter';
import Hamburger from '../icons/Hamburger';
import Logo from '../icons/Logo';
import Minus from '../icons/Minus';
import Pin from '../icons/Pin';
import Play from '../icons/Play';
import Play2 from '../icons/PlayTwo';
import Plus from '../icons/Plus';
import Profile from '../icons/Profile';
import Search from '../icons/Search';
import Stars from '../icons/Stars';
import Star from '../icons/Star';
import Logo2 from '../icons/Logo2';

const renderIcon = icon => {
	if (!icon) return null;

	switch (icon) {
	case 'arrow':
		return <Arrow />;
	case 'calendar':
		return <Calendar />;
	case 'cart':
		return <Cart />;
	case 'check-icon':
		return <CheckIcon />;
	case 'clock':
		return <Clock />;
	case 'close':
		return <Close />;
	case 'color-swatch':
		return <ColorSwatch />;
	case 'document':
		return <Document />;
	case 'filter':
		return <Filter />;
	case 'hamburger':
		return <Hamburger />;
	case 'logo':
		return <Logo />;
	case 'logo2':
		return <Logo2 />;
	case 'minus':
		return <Minus />;
	case 'pin':
		return <Pin />;
	case 'play':
		return <Play />;
	case 'play2':
		return <Play2 />;
	case 'plus':
		return <Plus />;
	case 'profile':
		return <Profile />;
	case 'search':
		return <Search />;
	case 'stars':
		return <Stars />;
	case 'star':
		return <Star />;
	default:
		return null;
	}
};

export default renderIcon;
