import * as React from 'react';

function CartIcon(props) {
	return (
		<svg
			xmlns={`http://www.w3.org/2000/svg`}
			fill={`none`}
			viewBox={`0 0 25 20`}
			width={23}
			height={18}
			{...props}
		>
			<title>Cart</title>
			<path
				stroke={`currentColor`}
				strokeMiterlimit={10}
				strokeWidth={1.8}
				d={`M20.208 14.587H7.714L4.922 4.723H23l-2.792 9.864zM8.759 19.254L3.918 1M0 1.619h4.71M7.953 18.625H18.71`}
			/>
		</svg>
	);
}

export default CartIcon;
