import * as React from 'react';

function Arrow(props) {
	return (
		<svg
			xmlns={`http://www.w3.org/2000/svg`}
			fill={`none`}
			width={28}
			height={22}
			viewBox={`0 0 28 22`}
			{...props}
		>
			<path
				stroke={`currentColor`}
				strokeWidth={2}
				d={`M0 10.96h26m-10.002 9.939 9.85-9.95L15.998 1`}
			/>
		</svg>
	);
}

export default Arrow;
