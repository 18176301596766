import React from "react";

import classNames from "classnames";
import "./css/Instructors.css";

import Tooltip from "./Tooltip";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { findImage } from "../assets/utils/functions.js";
import uniqueId from "uniqid";

function Instructors({ instructors = [] }) {
    const data = useStaticQuery(graphql`
        {
            allFile {
                nodes {
                    name
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 80, layout: CONSTRAINED)
                    }
                }
            }
        }
    `);

    const ns = "instructors";
    const rootClassnames = classNames({
        [ns]: true,
    });

    if (!instructors.length) return null;

    return (
        <div className={rootClassnames}>
            {instructors.map((instructor, i) => {
                const { img: instructorImage, name, credentials, tooltip = {} } = instructor || {};
                const insrtuctorClassnames = classNames({
                    [`${ns}__instructor`]: true,
                    [`${ns}__instructor--tooltip`]: !!Object.keys(tooltip).length,
                });

                return (
                    <div
                        className={insrtuctorClassnames}
                        key={uniqueId("bb")}
                    >
                        {instructorImage && (
                            <GatsbyImage
                                className={`${ns}__instructor-img`}
                                image={getImage(findImage(data, instructorImage))}
                                alt={instructorImage.split("@")[0]}
                                loading="eager"
                            />
                        )}
                        <div className={`${ns}__instructor-body`}>
                            <div className={`${ns}__instructor-name`}>
                                <span>By</span>
                                <button>
                                    <span>{name}</span>
                                    <Tooltip {...tooltip} />
                                </button>
                            </div>
                            {credentials && <p className={`${ns}__instructor-credentials`}>{credentials}</p>}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Instructors;
