import React from "react";

import classNames from "classnames";
import "./css/Carousel.css";

import Instructors from "./Instructors";

import uniqueId from "uniqid";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { findImage } from "../assets/utils/functions.js";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectFade } from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade]);

function Carousel({ heading, headingVariant, subtitle, copy, slides, instructors = [], reverse, bgColor }) {
    const data = useStaticQuery(graphql`
        {
            allFile(filter: { relativeDirectory: { eq: "carousel" } }) {
                nodes {
                    name
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 80, layout: CONSTRAINED)
                    }
                }
            }
        }
    `);

    const ns = "carousel";
    const rootClassnames = classNames({
        [`${ns}`]: true,
        [`${ns}--reverse`]: reverse,
        [`${ns}--${headingVariant}`]: headingVariant,
        [`${ns}--${bgColor}`]: bgColor,
    });

    return (
        <section className={`${rootClassnames} ${slides.length < 2 && "hidden"}`}>
            <div className={`${ns}__container`}>
                <div className={`${ns}__heading`}>
                    <h2
                        className={`${ns}__title`}
                        dangerouslySetInnerHTML={{ __html: heading }}
                    />
                    {subtitle && <p className={`${ns}__subtitle`}>{subtitle}</p>}
                    {!!instructors.length && (
                        <div className={`${ns}__instructors`}>
                            <Instructors instructors={instructors} />
                        </div>
                    )}
                    {copy && (
                        <div
                            className={`${ns}__copy`}
                            dangerouslySetInnerHTML={{ __html: copy }}
                        />
                    )}
                </div>
                <Swiper
                    className={`${ns}__slides-container`}
                    slidesPerView={1}
                    navigation
                    pagination={{
                        clickable: true,
                    }}
                    fadeEffect
                    initialSlide={0}
                >
                    {slides.map((slide) => {
                        const { tag, title, subtitle, benefits, technique, copy, video, image } = slide;

                        const pathToImage = image && getImage(findImage(data, image));

                        return (
                            <SwiperSlide
                                key={uniqueId("bb")}
                                className={`${ns}__slide`}
                            >
                                <div className={`${ns}__slide-body`}>
                                    <p className={`${ns}__slide-body-tag`}>{tag}</p>
                                    <p className={`${ns}__slide-body-title`}>{title}</p>
                                    {subtitle && (
                                        <p
                                            className={`${ns}__slide-body-text`}
                                            dangerouslySetInnerHTML={{ __html: subtitle }}
                                        />
                                    )}
                                    {benefits && (
                                        <p className={`${ns}__slide-body-text`}>
                                            <b>Benefits:</b> {benefits}
                                        </p>
                                    )}
                                    {technique && (
                                        <div className={`${ns}__slide-body-text`}>
                                            <b>How to Do It: </b>
                                            {typeof technique === "string" ? (
                                                <>
                                                    <br /> {technique}
                                                </>
                                            ) : (
                                                <ul>
                                                    {technique.map((item) => (
                                                        <li
                                                            key={uniqueId("bb")}
                                                            dangerouslySetInnerHTML={{
                                                                __html: item,
                                                            }}
                                                        />
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    )}
                                    {copy && (
                                        <div
                                            className={`${ns}__slide-body-text ${ns}__slide-body-text--copy`}
                                            dangerouslySetInnerHTML={{ __html: copy }}
                                        />
                                    )}
                                </div>
                                <div className={`${ns}__slide-media`}>
                                    {video && (
                                        <video
                                            loop
                                            muted
                                            autoPlay
                                            playsInline
                                        >
                                            <source
                                                src={video}
                                                type="video/mp4"
                                            />
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                    {image && (
                                        <GatsbyImage
                                            image={pathToImage}
                                            alt={image.split("@")[0]}
                                            loading="eager"
                                        />
                                    )}
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </section>
    );
}

export default Carousel;
