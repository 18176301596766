import * as React from 'react';

function Minus(props) {
	return (
		<svg
			width={17}
			height={17}
			viewBox={`0 0 17 17`}
			fill={`none`}
			xmlns={`http://www.w3.org/2000/svg`}
			{...props}
		>
			<path
				d={`M0 8.5H17`}
				stroke={`currentColor`}
				strokeWidth={1.6}
			/>
		</svg>
	);
}

export default Minus;
