import * as React from 'react';

function Profile(props) {
	return (
		<svg
			xmlns={`http://www.w3.org/2000/svg`}
			fill={`none`}
			viewBox={`0 0 21 20`}
			width={19}
			height={22}
			{...props}
		>
			<path
				stroke={`currentColor`}
				strokeMiterlimit={10}
				strokeWidth={1.8}
				d={`M19.828 18.89c-1.368-3.202-5.1-5.494-9.495-5.494S2.207 15.699.828 18.9M10.334 12.61c3.246 0 5.878-2.62 5.878-5.855C16.212 3.521 13.58.9 10.334.9S4.457 3.52 4.457 6.755c0 3.234 2.631 5.856 5.877 5.856z`}
			/>
		</svg>
	);
}

export default Profile;
