import * as React from 'react';

function Search(props) {
	return (
		<svg
			xmlns={`http://www.w3.org/2000/svg`}
			fill={`none`}
			viewBox={`0 0 20 22`}
			width={19}
			height={22}
			{...props}
		>
			<path
				stroke={`currentColor`}
				strokeMiterlimit={10}
				strokeWidth={2}
				d={`M18.82 20.471l-4.32-4.876`}
			/>
			<path
				stroke={`currentColor`}
				strokeMiterlimit={10}
				strokeWidth={1.8}
				d={`M9.008 17.349c4.423 0 8.009-3.555 8.009-7.939 0-4.385-3.586-7.939-8.009-7.939S1 5.025 1 9.41c0 4.384 3.585 7.939 8.008 7.939z`}
			/>
		</svg>
	);
}

export default Search;
