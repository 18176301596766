// Imports
import React from "react";

// import { StaticImage } from "gatsby-plugin-image";

// COMPONENTS
import Hero from "../components/Hero";
import Carousel from "../components/Carousel";
import VideoFull from "../components/VideoFull";
// import Hr from "../components/HR";
// import CardSlider from "../components/CardSlider";
// import Block from "../components/Block";
// import Block2 from "../components/Block-2";

// DATA
import {
    imageSlides,
    videoSlides,
    nicoGonzalesSlides,
    giftOfLove,
    selfCare,
    stressSlides,
    revampedResolutions,
    mindfulness,
} from "../assets/utils/data";

// ======== Component ========
const Index = () => {
    return (
        <>
            <Hero />
            {/* <Hr /> */}
            <Carousel
                bgColor={"blue"}
                heading={"Revamped Resolutions: Small Steps = Big Gains!"}
                headingVariant={"title-1000"}
                copy={`
                    <p>It’s the start of a new year. Hooray! This can come with much excitement and potential resolutions that have been made after a winter of indulgence. Did you know that, “Researchers suggest that only 9% of Americans that make resolutions complete them. In fact, research goes on to show that 23% of people quit their resolution by the end of the first week, and 43% quit by the end of January.”</p> <p>This year, instead of going for the “big kahuna” resolution, such as losing 15 lbs. in 30 days or getting rid of all sugar in your diet, maybe consider micro-stepping your way to longer-lasting sustainable results. In the book Your Time to Thrive by Arianna Huffington, she talks about science supporting the need to “start small” to impact behavior change. Such discussions are also had in Atomic Habits by James Clear as well as behavior change researcher B.J. Fogg.</p>
                    <p>Here are some ideas from our Balanced Body Instructor and Educator team to get you started thinking about what tiny shifts you can realistically incorporate and stack in your life this new year to produce long-term gains.</p>
                `}
                slides={revampedResolutions}
            />
            <Carousel
                heading={"Connect Mind + Body with 12 Feel Good Movements"}
                headingVariant={"title-800"}
                instructors={[
                    {
                        name: "Nico Gonzalez",
                        img: "nico-gonzalez",
                        credentials: "NCPT, ACE, BBMI",
                        // tooltip: {
                        //     links: [
                        //         {
                        //             label: "Website",
                        //             link: "https://www.Vitalonlinewellness.com",
                        //         },
                        //     ],
                        // },
                    },
                ]}
                slides={nicoGonzalesSlides}
            />
            <Carousel
                bgColor={"blue"}
                heading={"Five Simple Mindfulness Meditations"}
                slides={mindfulness}
            />
            <VideoFull
                title={"Movement Stacking for Mood & Mobility!"}
                thumb="MovementStacking_TN"
                video="https://www.youtube.com/embed/Ppp51LZ5E3Q?si=Ee-ir04-_sRJO-4T?mute=1&autoplay=1"
                instructors={[
                    {
                        name: "Lindy Royer",
                        img: "lindy-royer@2x",
                        credentials: "PT, NCPT, Balanced Body Educator",
                    },
                ]}
            />
            <VideoFull
                bgColor={"blue"}
                title={"Gratitude Everyday Workout"}
                thumb="Gratitude_THUMB"
                video="https://www.youtube.com/embed/tnnRlMyL1FU?mute=1&autoplay=1"
                instructors={[
                    {
                        name: "Joy Puleo",
                        img: "joy-puleo@2x",
                        credentials: "MA, NCPT, BBMI, Balanced Body Director of Education",
                        // tooltip: {
                        //     credentials: "MA, NCPT, BBMI",
                        //     title: "Director of Education, Balanced Body",
                        // },
                    },
                    {
                        name: "Portia Page",
                        img: "portia-page@2x",
                        credentials: "NCPT, ACE, BBMI, Balanced Body Curriculum Coordinator",
                        // tooltip: {
                        //     credentials: "NCPT, ACE, BBMI",
                        //     title: "Education Curriculum Coordinator, Balanced Body",
                        // },
                    },
                ]}
            />
            {/* <Carousel
                heading={"8 Movements to Boost Your Mental Health"}
                subtitle={"By Lindy Royer, PT, NCPT, Balanced Body Master Instructor"}
                slides={videoSlides}
            /> */}
            {/* <HR /> */}
            {/* <CardSlider /> */}
            {/* <Block
                title={"10/10: Give Back to Active Minds"}
                desc={
                    "To celebrate World Mental Health Day, Balanced Body will donate up to $10,000 to Active Minds based on sales today, October 10, 2022. Your support will support the premier organization for young people and mental health"
                }
                button={{ label: "Learn More about Active Minds", style: "button--dark" }}
            >
                <StaticImage
                    src="../assets/images/block/donation@2x.jpg"
                    alt="Donate image"
                    placeholder="blurred"
                    quality={90}
                    loading="eager"
                    width={745}
                />
            </Block>
            <Block2
                date={"Healthy Mind, Healthy Body"}
                // time={"9 AM PT"}
                title={"Free Mind & Body Workout to Help You Focus and Feel Better!"}
                desc={
                    "Master Pilates Instructor and Physical Therapist, Lindy Royer, leads a mind + body workout focused on enhancing your mental health while getting your blood pumping."
                }
                button={{ label: "Watch Now!" }}
            >
                <StaticImage
                    src="../assets/images/block/iglive@2x.jpg"
                    alt="Donate image"
                    placeholder="blurred"
                    quality={90}
                    loading="eager"
                />
            </Block2> */}
        </>
    );
};

export default Index;
