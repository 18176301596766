import * as React from 'react';

function CheckIcon(props) {
	return (
		<svg
			width={16}
			height={13}
			fill={`none`}
			xmlns={`http://www.w3.org/2000/svg`}
			{...props}
		>
			<path
				d={`M1 6.714 5.9 11 15 1`}
				stroke={`currentColor`}
				strokeWidth={2.6}
			/>
		</svg>
	);
}

export default CheckIcon;
