import * as React from "react";

function Play2(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="30"
            height="22"
            viewBox="0 0 30 22"
            {...props}
        >
            <path
                fill="white"
                d="M29.7012 5C29.7012 5 29.4082 2.93164 28.5059 2.02344C27.3633 0.828125 26.0859 0.822265 25.5 0.751953C21.3047 0.447265 15.0059 0.447266 15.0059 0.447266H14.9941C14.9941 0.447266 8.69531 0.447265 4.5 0.751953C3.91406 0.822265 2.63672 0.828125 1.49414 2.02344C0.591797 2.93164 0.304688 5 0.304688 5C0.304688 5 0 7.43164 0 9.85742V12.1309C0 14.5566 0.298828 16.9883 0.298828 16.9883C0.298828 16.9883 0.591797 19.0566 1.48828 19.9648C2.63086 21.1602 4.13086 21.1191 4.79883 21.248C7.20117 21.4766 15 21.5469 15 21.5469C15 21.5469 21.3047 21.5352 25.5 21.2363C26.0859 21.166 27.3633 21.1602 28.5059 19.9648C29.4082 19.0566 29.7012 16.9883 29.7012 16.9883C29.7012 16.9883 30 14.5625 30 12.1309V9.85742C30 7.43164 29.7012 5 29.7012 5ZM11.9004 14.8906V6.45898L20.0039 10.6895L11.9004 14.8906Z"
            />
        </svg>
    );
}

export default Play2;
