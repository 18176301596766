import * as React from 'react';

function Pin(props) {
	return (
		<svg
			xmlns={`http://www.w3.org/2000/svg`}
			fill={`none`}
			width={17}
			height={14}
			viewBox={`0 0 17 14`}
			{...props}
		>
			<path
				stroke={`currentColor`}
				strokeMiterlimit={`10`}
				strokeWidth={`1.5202`}
				d={`M10.8391 13.1701c1.2702 0 2.3-1.0298 2.3-2.3 0-1.2703-1.0298-2.3-2.3-2.3-1.2703 0-2.3 1.0297-2.3 2.3 0 1.2702 1.0297 2.3 2.3 2.3ZM0 10.8701h8.56M12.8906 10.8701h3.25M5.3 5.36c1.2703 0 2.3-1.0297 2.3-2.3 0-1.2703-1.0297-2.3-2.3-2.3C4.0298.76 3 1.7897 3 3.06c0 1.2703 1.0297 2.3 2.3 2.3ZM16.1403 3.06h-8.57M3.25 3.06H0`}
			/>
		</svg>
	);
}

export default Pin;
